import React, { Component } from "react";
import ReactJson from 'react-json-view'
import "styled-components/macro";

import Api from "./Api";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error) {
    function stringifyError(err) {
      const seen = new WeakSet();
      return JSON.stringify(err, (key, value) => {
        if (typeof value === 'object' && value !== null)
        {
          if (seen.has(value))
          {
            return '[Circular]';
          }
          seen.add(value);
        }
        return value;
      });
    }
    console.log('yyy', stringifyError(error))
    this.setState({ error: stringifyError(error) })
    this.log(error);
  }

  render() {
    const { hasError, error } = this.state;
    if (hasError)
    {
      // You can render any custom fallback UI
      return (
        <div
          css={`
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 50px 30px;
            font-family: "Inter", sans-serif;
          `}
        >
          <div>You encountered an error.</div>
          <ReactJson src={error} />
        </div>
      );
    }

    return this.props.children;
  }

  log = async (error) => {
    const data = {
      name: error.name,
      stack: error.stack,
      message: error.message,
      timestamp: new Date(),
    };

    const errorLog = (await Api().post("/error-log", data)).data
  };
}
