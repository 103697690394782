import React, { Component, createContext } from "react";
import localforage from "localforage";

import Api from "../Api";
import notificationLoading from "../utils/notificationLoading";
import notificationLoadingSuccess from "../utils/notificationLoadingSuccess";

export const ArticlesContext = createContext();
export class ArticlesContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      articlesCache: [],
      setArticlesCache: this.setArticlesCache,
      refreshArticlesCache: this.refreshArticlesCache,
    };
  }

  render() {
    return (
      <ArticlesContext.Provider value={this.state}>
        {this.props.children}
      </ArticlesContext.Provider>
    );
  }

  setArticlesCache = (articlesCache) => {
    return this.setState(() => ({ articlesCache: articlesCache }));
  };

  refreshArticlesCache = async () => {
    // exists in LS?
    // yes> grab it
    // no> xhrfetch, then LS it
    const articlesCacheOld = await localforage.getItem("articlesCache");

    if (articlesCacheOld) {
      this.setArticlesCache(articlesCacheOld);
      return;
    } else {
      const notificationId = await notificationLoading(
        "Loading articles",
        {},
        1000
      );
      const articlesCache = (
        await Api({ timeout: 40000 }).get(
          `/feed?subscriber=${localStorage.idUser}`
        )
      ).data;
      await notificationLoadingSuccess(notificationId, "Loaded articles");

      await localforage.setItem("articlesCache", articlesCache);
      this.setArticlesCache(articlesCache);
      return;
    }
  };
}
